import Layout from "./common/layout"
import Head from "./common/Head"
import BannerHeader from "pages/common/BannerHeader"
import { StaticImage } from "gatsby-plugin-image"
import { useFormatter } from "helpers/i18n"
import bannerMobile from "images/privacy-mobile.svg"
import TermsOfServiceVN from "./termsOfService/components/TermsOfServiceVN"
import TermsOfServiceEN from "./termsOfService/components/TermsOfServiceEN"

const TermOfService = () => {
  const { __, currentLocale } = useFormatter()
  const banner = "../images/privacy.png"
  return (
    <>
      <Head
        showCaptcha={false}
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/clients-partner-meta.png"
        }
        title={__({ defaultMessage: "Terms Of Services" })}
        description={__({
          defaultMessage:
            "By using our Services or conducting the transactions with us, Customer is deemed to consent to these terms and conditions specified in this Term of Service",
        })}
      />
      <Layout>
        <div>
          <BannerHeader
            breadcrumb={[
              {
                label: `${__({ defaultMessage: "Home/" })}`,
                url: "/",
              },
              { label: `${__({ defaultMessage: "Terms Of Service" })}` },
            ]}
            banner={
              <StaticImage
                layout="fullWidth"
                src={banner}
                alt=""
                fetchpriority="high"
                quality={100}
              />
            }
            mobileBanner={
              <img src={bannerMobile} alt="" style={{ width: "100%" }} />
            }
            logo={""}
            listTitle={[`${__({ defaultMessage: "Terms Of Service" })}`]}
            description={`${__({
              defaultMessage:
                "By using our Services or conducting the transactions with us, Customer is deemed to consent to these terms and conditions specified in this Term of Service.",
            })}`}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 64,
            }}
          >
            <div style={{ flex: 1 }}></div>
            {currentLocale == "vi" ? (
              <TermsOfServiceVN />
            ) : (
              <TermsOfServiceEN />
            )}
            <div style={{ flex: 1 }}></div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default TermOfService
